import _ from 'lodash';
import { DropdownModel } from '@common/models/geral/DropdownModel';
import helpers from '../../../common/utils/helpers';

export class ModeloInstrumentoModel {
  constructor({
    id,
    nome,
    descricao,
    valor = null,
    marca,
    tipoInstrumento,
    faixas = [],
    camposAdicionais = [],
    vistasExplodidas = [],
    imagem = null,
    nomeImagem,
  }) {
    this.id = id;
    this.nome = nome;
    this.descricao = descricao;
    this.valor = valor;
    this.imagem = imagem;
    this.nomeImagem = nomeImagem;
    this.vistasExplodidas = vistasExplodidas;

    this.marca = marca ? new DropdownModel(marca) : null;
    if (tipoInstrumento)
      this.tipoInstrumento = new DropdownModel(tipoInstrumento);
    this.faixas = faixas;

    this.faixas.forEach((faixa) => {
      faixa.unidadeMedidaId = faixa.unidadeMedida.id;
      if (faixa.id) faixa.flagJaExistente = true;

      if (faixa.modeloInstrumentoFaixaPonto) {
        faixa.modeloInstrumentoFaixaPonto.forEach((ponto) => {
          if (ponto.id) ponto.flagJaExistente = true;
        });
      }
    });
    if (camposAdicionais)
      this.camposAdicionais = camposAdicionais.map((el) => {
        let valorInput = el.valor;
        if (el.tipoCampo == 'ListaMultiplaEscolha') {
          if (valorInput.includes(',')) {
            valorInput = valorInput.split(',');
          } else {
            valorInput = [valorInput];
          }
        }
        return {
          value: el.campoAdicional.id,
          text: el.campoAdicional.nome,
          valorInput: valorInput,
        };
      });

    if (imagem && nomeImagem) {
      const ctx = this;
      helpers
        .converterBase64ParaFile(
          imagem,
          nomeImagem,
          'image/png'
        )
        .then(function (file) {
          ctx.fileImagem = file;
        });
    }


  }

  get request() {
    let retorno = _.cloneDeep(this);

    retorno.tipoInstrumentoId = this.tipoInstrumento?.value;
    retorno.faixas.forEach((faixa) => {
      
      faixa.unidadeMedidaId = faixa.unidadeMedida.id;
      if(!faixa.tipoInstrumentoFaixaId){
        faixa.tipoInstrumentoFaixaId = faixa?.id;
      }
      if (!faixa.flagJaExistente) faixa.id = null;
      if (faixa.modeloInstrumentoFaixaPonto)
        faixa.modeloInstrumentoFaixaPonto.forEach((ponto) => {
          if (!ponto.flagJaExistente) ponto.id = null;
        });
      faixa.pontos = _.cloneDeep(faixa.modeloInstrumentoFaixaPonto);
    });
    retorno.camposAdicionais = [];

    retorno.camposAdicionais = this.camposAdicionais.reduce(
      (result, { value, valorInput, tipoCampo }) => {
        if (!valorInput) return result;
        let valor;
        if (tipoCampo == 'Data') {
          valor = valorInput.substr(0, 10);
        } else if (Array.isArray(valorInput)) {
          valor = valorInput.join(',');
        } else {
          valor = valorInput;
        }
        result.push({
          campoAdicionalId: value,
          valor: valor,
        });
        return result;
      },
      []
    );
    retorno.marcaId = this.marca?.value;

    if (retorno.vistasExplodidas) {
      retorno.vistasExplodidas = retorno.vistasExplodidas.map((pontoImagem) => {
        const informacoesDoPonto = {
          coordenadaX: pontoImagem.x || pontoImagem.coordenadaX,
          coordenadaY: pontoImagem.y || pontoImagem.coordenadaY,
          iterador: pontoImagem.iterador.toString(),
          itemId: pontoImagem.itemId || pontoImagem.item.id,
        };
        informacoesDoPonto.pontoId = pontoImagem.pontoId;
        return informacoesDoPonto;
      });
    }

    return retorno;
  }
}
