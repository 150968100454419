<template>
  <v-form ref="form">
    <tabela-padrao-prime-vue
      ref="tabela"
      :dados="tabela.dados"
      :colunas="tabela.colunas"
      linha-hover
      class="mt-2"
      sem-acoes
      sem-paginacao
      :por-pagina="-1"
      :mostrar-seletor="false"
      ajustar-altura-linha
    >
      <!-- eslint-disable vue/valid-v-slot-->
      <template v-slot:nome="{ slotProps }">
        <strong>{{ slotProps.data.nome }}
          <span v-if="slotProps.data.obrigatorio"> * </span>
        </strong>
      </template>
      <template v-slot:valor="{ slotProps }">
        <input-condicional-campos-adicionais
          v-model="buscarValorInput(slotProps.data).valorInput"
          :tipo-input="slotProps.data.tipoCampo"
          :opcoes="slotProps.data.itens"
          :obrigatorio="ehVisualizar ? false : slotProps.data.obrigatorio"
          :disabled="!participanteLogadoConta || ehVisualizar"
          class="flex-fill  input-tabela-campos-adicionais my-1 "
        />
      </template>
      <template v-slot:valorPadrao="{ slotProps }">
        <span
          v-if="
            typeof slotProps.data.valorPadrao === 'boolean' ||
              slotProps.data.valorPadrao == 'true' ||
              slotProps.data.valorPadrao == 'false'
          "
          class="primary-grey-400--text"
        >
          {{
            slotProps.data.valorPadrao
              ? $t(`modulos.participantes.formulario.campos_adicionais.true`)
              : $t(`modulos.participantes.formulario.campos_adicionais.false`)
          }}
        </span>
        <span
          v-else
          class="primary-grey-400--text"
        >
          {{
            slotProps.data.valorPadrao
              ? formatarValorPadrao(slotProps.data)
              : ''
          }}
        </span>
      </template>
    </tabela-padrao-prime-vue>
  </v-form>
</template>
<script>
import CampoAdicionalService from '@common/services/cadastros/CampoAdicionalService';
import InputCondicionalCamposAdicionais from './inputs/InputCondicionalCamposAdicionais.vue';
import _ from 'lodash';
import helpers from '@/common/utils/helpers'
export default {
  components: { InputCondicionalCamposAdicionais },
  props: {
    form: {},
    participanteLogadoConta: { type: Boolean, default: false },
  },
  data() {
    return {
      conteudo: [],
      camposAdicionaisValido: false,
      tabela: {
        dados: [],
        colunas: [
          {
            value: 'nome',
            text: this.$t('modulos.modelo_instrumento.campos_adicionais.nome'),
            sortable: false,
          },
          {
            value: 'valor',
            text: this.$t(
              'modulos.modelo_instrumento.campos_adicionais.valor_padrao'
            ),
            sortable: false,
            width: 440,
          },
          {
            value: 'valorPadrao',
            text: this.$t(
              'modulos.modelo_instrumento.campos_adicionais.valor_input'
            ),
            sortable: false,
            width: 380,
          },
        ],
      },
    };
  },
  computed: {
    ehVisualizar(){
      return this.$route.name === 'modelo-instrumento-visualizar';
    },
  },
  watch: {
    conteudo: {
      handler() {
        this.form.camposAdicionais = this.conteudo;
        this.$emit('validarCamposAdicionais', this.$refs.form.validate());
      },
      deep: true,
    },
    'form.tipoInstrumento': function () {
      this.listar();
    },
  },
  mounted() {
    let conteudoClonado = _.cloneDeep(this.form.camposAdicionais);

    if (conteudoClonado) {
      this.conteudo = conteudoClonado;
      this.listar();
    }
  },
  methods: {
    listar: function () {
  let parametros = {
    entidade: 1,
    tiposInstrumentosIds: this.form.tipoInstrumento?.value,
  };
  if (!this.form.tipoInstrumento?.value) {
    this.toastAlerta(
      this.$t(`modulos.modelo_instrumento.erros.escolher_tipo_instrumento`)
    );
    return;
  }
  this.$store.dispatch('Layout/iniciarCarregamento');
  CampoAdicionalService.listar(parametros)
    .then((res) => {
      if (res.data && Array.isArray(res.data.items)) {
        this.tabela.dados = res.data.items;
      } else {
        this.tabela.dados = [];
        console.error('Dados inválidos recebidos do serviço');
      }
    })
    .catch((error) => {
      console.error('Erro ao listar dados:', error);
    })
    .finally(() => {
      this.$store.dispatch('Layout/terminarCarregamento');
    });
},
    buscarValorInput: function (item) {
      let obj = this.conteudo.find((conteudo) => conteudo.value == item.id);

      if (obj == null) {
        let valor = item.valorPadrao;

        if (valor && item.tipoCampo == 'ListaMultiplaEscolha') {
          if (valor.includes(',')) {
            valor = valor.split(',');
          } else {
            valor = [valor];
          }
        }

        obj = {
          value: item.id,
          text: item.nome,
          valorInput: valor,
        };

        this.conteudo.push(obj);
      }
      obj.tipoCampo = item.tipoCampo;

      return obj;
    },
    formatarValorPadrao: function(item){
      if(item.tipoCampo == 'ListaMultiplaEscolha' && item.valorPadrao.includes(',')){
        let listaMultipla = item.valorPadrao.split(',');
        return listaMultipla.join(', ');
      }
      if(item.tipoCampo == 'Data'){
        return helpers.formatarDataBr(item.valorPadrao);
      }
      return item.valorPadrao;
    }
  },

};
</script>

