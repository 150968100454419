<template lang="">
  <modal-padrao
    ref="modal-filtro-instrumento"
    max-width="70%"
    :titulo="$t('modulos.modelo_instrumento.formulario.associar_desassociar_tipos_instrumentos')"
    :titulo-ok="$t('geral.botoes.confirmar')"
    :titulo-cancelar="$t('geral.botoes.cancelar')"
    @ok="associarFaixas"
  >
    <tabela-padrao-prime-vue
      v-model="tabela.selecionados"
      :dados="tabela.dados"
      class="mt-2"
      :colunas="tabela.colunas"
      :mostrar-acoes="false"
      filtro-geral
      :quantidade-itens="tabela.quantidadeItens"
      :por-pagina="tabela.porPagina"
      :quantidade-paginas="tabela.quantidadePaginas"
      :pagina-atual="tabela.paginaAtual"
      paginacao-em-memoria
      sem-paginacao
      :filters="filters"
      ajustar-altura-linha
      :global-filters="tabela.colunas.map(c => c.value)"
    >
      <template v-slot:qtd="{ slotProps }">
        <div> {{ slotProps.data.tipoInstrumentoFaixaPonto.length }}</div>
      </template>
    </tabela-padrao-prime-vue>
  </modal-padrao>
</template>
<script>
import TipoInstrumentoService from '@common/services/cadastros/TipoInstrumentoService.js'
import { FilterMatchMode } from 'primevue/api';
import helpers from '@common/utils/helpers'
import _ from 'lodash'
export default {
  data() {
    return {
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'descricao',
            text: this.$t('modulos.modelo_instrumento.tabela.faixasTable.descricao'),
            sortable: true,
            width: 400,
          },
          {
          value: 'amplitudeMinima',
          text: this.$t('modulos.tipo_instrumento.tabela.faixas.amplitude_minima'),
          sortable: true,
          formatter: v => helpers.formatarNumeroComPrecisao(v)
          },
          {
            value: 'amplitudeMaxima',
            text: this.$t('modulos.tipo_instrumento.tabela.faixas.amplitude_maxima'),
            sortable: true,
            formatter: v => helpers.formatarNumeroComPrecisao(v)
          },
          {
            value: 'unidadeMedida',
            text: this.$t('modulos.tipo_instrumento.tabela.faixas.unidade_medida'),
            sortable: true,
            formatter: v => `${v?.nome} (${v?.simbolo})`
          },
          {
            value: 'resolucao',
            text: this.$t('modulos.modelo_instrumento.tabela.faixasTable.resolucao'),
            sortable: true,
            formatter: v => helpers.formatarNumeroComPrecisao(v)
          },
          {
            value: 'qtd',
            text: this.$t('modulos.modelo_instrumento.tabela.faixasTable.qtd_pontos_calibracao'),
            sortable: true,
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
      referenciaForm: null,
    };
  },
  methods: {
    abrirModal: function (form) {
      this.referenciaForm = form;
      this.listarFaixas(form.tipoInstrumento?.value);
      this.$refs['modal-filtro-instrumento'].abrirModal();
    },
    listarFaixas: function (tipoInstrumento) {
      TipoInstrumentoService.buscar(tipoInstrumento)
        .then((res) => {
          this.tabela.selecionados = [];
          this.tabela.dados = res.data.faixa;
          this.tabela.quantidadeItens = res.data.totalItems;
          this.tabela.quantidadePaginas = res.data.totalPages;
          this.tabela.paginaAtual = this.paginaAtual;
          this.tabela.porPagina = this.porPagina;
          this.tabela.selecionados = []
          this.referenciaForm.faixas.forEach(i => {
            const itemSelecionado = this.tabela.dados.find(rf => rf.descricao == i.descricao)
            if (itemSelecionado) this.tabela.selecionados.push(itemSelecionado)
          })
        })
    },
    associarFaixas(){
      this.tabela.selecionados.forEach(faixa => {
        const verificaFaixaExistente = this.referenciaForm.faixas.find(item => {
          return item.descricao == faixa.descricao;
        })
        if (verificaFaixaExistente) return;
        this.referenciaForm.faixas.push(faixa);
      });
      this.referenciaForm.faixas.forEach(faixa => {
        if (!faixa.modeloInstrumentoFaixaPonto) {
          faixa.modeloInstrumentoFaixaPonto = _.cloneDeep(faixa.tipoInstrumentoFaixaPonto)
          delete faixa.tipoInstrumentoFaixaPonto
        }
      });
      this.$emit("atualizarTabela")
      this.$refs['modal-filtro-instrumento'].fecharModal();
    }
  },
}
</script>
