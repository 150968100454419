<template>
  <modal-padrao
    ref="modal-peca"
    max-width="80%"
    :titulo="$t('modulos.orcamentos.modais.pecas.titulo')"
    :titulo-ok="$t('geral.botoes.confirmar')"
    :titulo-cancelar="$t('geral.botoes.cancelar')"
    @ok="closeModal"
  >
    <tabela-padrao-prime-vue
      ref="tabela"
      v-model="tabela.selecionados"
      class="mt-2"
      :colunas="tabela.colunas"
      :dados="tabela.dados"
      :mostrar-acoes="false"
      filtros-com-colunas
      filtro-global-focus
      linha-hover
      :quantidade-itens="tabela.quantidadeItens"
      :por-pagina="tabela.porPagina"
      :quantidade-paginas="tabela.quantidadePaginas"
      :pagina="tabela.paginaAtual"
      ajustar-altura-linha
      selecionar-apenas-um
      @paginar="listarRegistros"
      @filtrarGlobal="() => listarRegistros()"
      @filtroGlobal="v => filtro = v"
      @fechar-modal-click-linha="() => closeModal(true)"
    />
  </modal-padrao>
</template>

<script>
import ItemService from '@/common/services/cadastros/ItemService';
import { cloneDeep } from 'lodash';
import helpers from '@common/utils/helpers';

export default {
  name: 'ServicoCalibracaoModal',
  props: {
    instrumentosIds: {
      type: Array,
      default: () => [],
    },
    localExecucaoId: {
      type: String,
      default: null,
    },
    hotspotData: {
      type: Object,
      default: () => {},
    },
    pontosJaSelecionados: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      idsJaSelecionados: [],
      modeloInstrumentoId: null,
      tipoInstrumentoId: null,
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'codigo',
            text: this.$t('modulos.orcamentos.modais.pecas.codigo'),
            sortable: false,
          },
          {
            value: 'descricao',
            text: this.$t('modulos.orcamentos.modais.pecas.descricao'),
            sortable: false,
          },
          {
            value: 'participanteFabricante.nome',
            text: this.$t('modulos.orcamentos.modais.pecas.fabricante'),
            sortable: false,
          },
          {
            value: 'valorVenda',
            text: this.$t('modulos.orcamentos.modais.pecas.valor'),
            sortable: false,
            formatter: (v) => helpers.adicionaCifraoValor(v),
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
      filtro: null,
    };
  },
  created() {
    this.modeloInstrumentoId = this.$route.params.id;
    this.tipoInstrumentoId = localStorage.getItem('TipoInstrumentoId');
  },
  methods: {
    abrirModal: function (selecionados) {
      this.idsJaSelecionados = cloneDeep(selecionados);
      this.tabela.selecionados = [];
      this.$refs['modal-peca'].abrirModal();
      this.listarRegistros();
      if (this.idsJaSelecionados.length > 0) {
        this.tabela.selecionados = cloneDeep(selecionados);
      }
      this.$refs?.tabela.focusFiltroGlobal();
    },
    listarRegistros: function (
      paginaAtual = this.tabela.paginaAtual,
      porPagina = this.tabela.porPagina
    ) {
      let parametros = {
        page: paginaAtual,
        amountPerPage: porPagina,
      };

      if(this.filtro) parametros.filter = this.filtro;

      if (this.localExecucaoId && this.instrumentosIds?.length == 0) {
        parametros.LocalExecucaoId = this.localExecucaoId;
      }
      if (this.tipoInstrumentoId)
        parametros.TipoInstrumentoId = this.tipoInstrumentoId;

      if (this.modeloInstrumentoId)
        parametros.ModeloInstrumentoId = this.modeloInstrumentoId;

      let instrumentoString = '';
      if (this.instrumentosIds.length > 0) {
        instrumentoString = '?';
        this.instrumentosIds.map((item) => {
          instrumentoString += `&InstrumentosId=${item}`;
        });
      }
      this.$store.dispatch('Layout/iniciarCarregamento');
      ItemService.buscarPecasGlobalVistaExplodida(parametros, instrumentoString)
        .then((res) => {
          const idsJaExistentes = new Set(
            this.pontosJaSelecionados.map(
              (itemExistente) => itemExistente.codigo
            )
          );
          this.tabela.dados = res.data.items.filter(
            (item) => !idsJaExistentes.has(item.codigo)
          );
          this.tabela.quantidadeItens = res.data.totalItems;
          this.tabela.quantidadePaginas = res.data.totalPages;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
          this.tipoPecasSelecionados = this.selected;
        });
    },
    closeModal(duploClique = false) {
      const itensParaRemover = [];
      // let itensParaAdicionar = [];
      if(duploClique) this.tabela.selecionados = this.tabela.selecionados[0];

      this.idsJaSelecionados.forEach((itemSelecionado) => {
        const item = this.tabela.selecionados?.find(
          (item) => item.id === itemSelecionado.id
        );
        if (!item) {
          itensParaRemover.push(itemSelecionado);
        }
      });

      if (itensParaRemover.length > 0) {
        this.$emit('remover-servicos', itensParaRemover);
      }

      // itensParaAdicionar = this.tabela.selecionados.filter((item) => {
      //   return !this.idsJaSelecionados.find(
      //     (itemSelecionado) => itemSelecionado.id === item.id
      //   );
      // });

      const participanteFabricante =
        this.tabela.selecionados?.participanteFabricante?.nome;

      this.$emit('close-modal-add', {
        type: '',
        selected: {
          ...this.tabela.selecionados,
          participanteFabricante:
            participanteFabricante ||
            this.$t('modulos.orcamentos.modais.pecas.desconhecido'),
        },
        hotspotData: this.hotspotData,
      });
      this.$refs['modal-peca'].fecharModal();
    },
  },
};
</script>
