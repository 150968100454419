<template>
  <div>
    <v-form ref="form">
      <div class="row mt-3">
        <input-text
          v-model="form.nome"
          class="col-12 col-md-3 pb-0"
          :label="$t('modulos.modelo_instrumento.formulario.nome')"
          obrigatorio
          trim
          :max="150"
          :disabled="!participanteLogadoConta || ehVisualizar"
        />
        <input-tipo-instrumento
          v-model="form.tipoInstrumento"
          class="col-12 col-md-3"
          :label="$t('modulos.instrumento.formulario.tipo_de_instrumento')"
          obrigatorio
          :disabled="ehVisualizar"
          :multiplos="false"
          @ok="trocaDeTipoInstrumento"
        />
        <input-marca
          v-if="buscarPermissao('Marca', 'Inserir')"
          v-model="form.marca"
          :label="$t('modulos.modelo_instrumento.formulario.marca')"
          :placeholder="$t('geral.inputs.selecione')"
          class="col-12 col-md-3"
          obrigatorio
          :multiplos="false"
          :disabled="ehVisualizar"
          :estilo-botao-adicionar="!ehVisualizar"
          :com-botao-adicionar="!ehVisualizar"
        >
          <template #botaoAdicionar>
            <botao-padrao botao-adicionar-input @click="abreModalMarca">
              <span style="font-size: 23px">
                {{ $t('geral.botoes.simbolo_soma') }}
              </span>
            </botao-padrao>
          </template>
        </input-marca>

        <input-money
          v-model="form.valor"
          class="col-12 col-md-3 pb-0"
          :label="$t('modulos.modelo_instrumento.formulario.valor')"
          :disabled="!participanteLogadoConta || ehVisualizar"
        />
        <input-text
          v-model="form.descricao"
          class="col-12 pt-0"
          :label="$t('modulos.modelo_instrumento.formulario.descricao')"
          :max="500"
          :disabled="!participanteLogadoConta || ehVisualizar"
        />
      </div>
      <marca-modal ref="modal-marca" @listar-marcas="buscarMarca" />
    </v-form>
  </div>
</template>
<script>
import MarcaModal from './modais/MarcaModal';
import MarcaService from '@common/services/cadastros/MarcaService';
import { DropdownModel } from '@common/models/geral/DropdownModel';
import { InputMarca, InputTipoInstrumento } from '@components/inputs';
import TipoInstrumentoService from '@common/services/cadastros/TipoInstrumentoService.js';
import helpers from '@common/utils/helpers';

import _ from 'lodash';

export default {
  components: {
    MarcaModal,
    InputMarca,
    InputTipoInstrumento,
  },
  props: {
    form: { type: Object, default: null },
    participanteLogadoConta: { type: Boolean, default: false },
  },
  computed: {
    ehVisualizar(){
      return this.$route.name === 'modelo-instrumento-visualizar';
    },
  },
  watch: {
    'form.tipoInstrumento': function () {
      localStorage.setItem(
        'TipoInstrumentoId',
        this.form.tipoInstrumento.value
      );
    },
  },
  methods: {
    abrirModalTiposInstrumentos() {
      this.$refs['modal-filtro-instrumento'].abrirModal(this.form);
    },
    abreModalMarca: function () {
      this.$refs['modal-marca'].abrirModal();
    },
    buscarMarca: function (marcaId) {
      MarcaService.buscar(marcaId).then((res) => {
        this.form.marca = new DropdownModel(res.data);
      });
    },
    buscarPermissao(funcionalidade) {
      return helpers.buscarStatePermissoes(this, funcionalidade, 'Listar');
    },
    trocaDeTipoInstrumento: async function () {
      this.form.faixas = [];
      await this.$nextTick();
      TipoInstrumentoService.buscar(this.form.tipoInstrumento?.value).then(
        (res) =>
          res.data?.faixa.forEach((faixa) => {
            faixa.modeloInstrumentoFaixaPonto = _.cloneDeep(
              faixa.tipoInstrumentoFaixaPonto
            );
            delete faixa.tipoInstrumentoFaixaPonto;
            this.form.faixas.push(faixa);
          })
      );
    },
  },
};
</script>
