<template lang="">
  <modal-padrao
    v-if="modalAberto"
    ref="modal-marca"
    max-width="50%"
    :titulo="$t('modulos.modelo_instrumento.tabela.marca')"
    :titulo-ok="$t('geral.botoes.salvar')"
    :mostrar-botoes="true"
    @ok="salvarMarca"
    @cancelar="fecharModal"
    @modal-fechado="destruirModal"
  >
    <marca-formulario
      ref="form-marca"
      :marca-modal="true"
      :aberto-como-modal="true"
      @form-marca="atualizarFormMarca"
    />
  </modal-padrao>
</template>
<script>
import MarcaFormulario from '@views/modulos/cadastros/marca/formulario.vue';
import MarcaService from '@common/services/cadastros/MarcaService';
import { MarcaModel } from '@common/models/cadastros/MarcaModel';
export default {
  components: { MarcaFormulario },
  data() {
    return {
      indiceAba: 0,
      form: new MarcaModel({}),
      valido: false,
      modalAberto: true,
    };
  },
  methods: {
    destruirModal: async function() {
      await this.$nextTick();
      this.modalAberto = false;
    },
    abrirModal: async function() {
      this.modalAberto = true;
      await this.$nextTick();
      this.$refs['modal-marca'].abrirModal();
    },
    fecharModal(marcaId) {
      if (typeof (marcaId) == 'string') this.$emit('listar-marcas', marcaId);
      this.$refs['modal-marca'].fecharModal();
    },
    atualizarFormMarca(formMarca) {
      this.form = formMarca
    },
    salvarMarca: function () {
      this.valido = this.form.nome;
      if (!this.valido) return
      this.$store.dispatch('Layout/iniciarCarregamento');
      MarcaService.salvar(this.form)
        .then((res) => {
          this.fecharModal(res.data)
          this.toastSucesso(this.$t(`modulos.marca.cadastro_sucesso`));
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
          this.valido = false
        });
    },
  },
};
</script>
