<template>
  <input-text
    v-if="tipoInput === 'Numerico'"
    v-model="conteudo"
    type="number"
    :placeholder="
      $t('modulos.modelo_instrumento.campos_adicionais.input_tipo_campo')
    "
    flat
    solo
    sem-label
    background-color="transparent"
    :obrigatorio="obrigatorio"
    :disabled="disabled"
  />
  <input-text
    v-else-if="tipoInput === 'Texto'"
    v-model="conteudo"
    :placeholder="
      $t('modulos.modelo_instrumento.campos_adicionais.input_tipo_campo')
    "
    flat
    solo
    sem-label
    background-color="transparent"
    :obrigatorio="obrigatorio"
    :disabled="disabled"
  />
  <input-date
    v-else-if="tipoInput === 'Data'"
    v-model="conteudo"
    flat
    solo
    sem-label
    background-color="transparent"
    :obrigatorio="obrigatorio"
    :disabled="disabled"
  />
  <input-text
    v-else-if="tipoInput === 'Angulo'"
    v-model="conteudo"
    v-mask="mascaraAngulo"
    :placeholder="
      $t('modulos.modelo_instrumento.campos_adicionais.input_tipo_campo')
    "
    flat
    solo
    sem-label
    background-color="transparent"
    :obrigatorio="obrigatorio"
    :disabled="disabled"
  />
  <input-select
    v-else-if="tipoInput === 'Booleano'"
    v-model="conteudo"
    :options="opcoesBooleano"
    flat
    solo
    sem-label
    background-color="transparent"
    :obrigatorio="obrigatorio"
    :disabled="disabled"
  />
  <input-combobox-text
    v-else-if="tipoInput === 'ListaEscolhaUnica'"
    v-model="conteudo"
    flat
    solo
    background-color="transparent"
    somente-combo-box
    chips
    sem-label
    :options="opcoes"
    :placeholder="$t('geral.inputs.selecione')"
    :multiplo="false"
    :append-icon="'$downArrow'"
    :obrigatorio="obrigatorio"
    :disabled="disabled"
  />

  <input-combobox-text
    v-else-if="tipoInput === 'ListaMultiplaEscolha'"
    v-model="conteudo"
    flat
    solo
    background-color="transparent"
    multiplo
    somente-combo-box
    chips
    sem-label
    :options="opcoes"
    :placeholder="$t('geral.inputs.selecione')"
    :append-icon="'$downArrow'"
    :obrigatorio="obrigatorio"
    :disabled="disabled"
  />
  <input-text
    v-else
    v-model="conteudo"
    flat
    solo
    sem-label
    background-color="transparent"
    type="number"
    :obrigatorio="obrigatorio"
    :disabled="disabled"
  />
</template>
<script>
import helpers from '@/common/utils/helpers';
export default {
  name: 'InputCondicionalModal',
  props: {
    value: {},
    tipoInput: { type: String, default: 'Numerico' },
    opcoes: { default: () => [] },
    obrigatorio: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      conteudo: '',
      opcoesBooleano: [
        {
          text: this.$t('enums.boleano.sim'),
          value: 'true',
        },
        {
          text: this.$t('enums.boleano.nao'),
          value: 'false',
        },
      ],
    };
  },
  computed: {
    mascaraAngulo() {
      return helpers.removerMascara(this.conteudo).length > 6
        ? `###°##'##"`
        : `##°##'##"`;
    },
  },
  watch: {
    value: {
      handler() {
        this.conteudo = this.value;
      },
      immediate: true,
    },
    conteudo: function () {
      this.$emit('input', this.conteudo);
    },
  },
};
</script>
