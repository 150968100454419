import cloneDeep from 'lodash/cloneDeep';
// import { DropdownModel } from '../geral/DropdownModel';
import helpers from '../../../common/utils/helpers';

export class VisaoExplodidaModel {
  constructor({
    imagem,
    nomeImagem,
  } = {}) {
    this.imagem = imagem;
    this.nomeImagem = nomeImagem;
    
    if (imagem && nomeImagem) {
      const ctx = this;
      helpers
        .converterBase64ParaFile(
          imagem,
          nomeImagem,
          'image/png'
        )
        .then(function (file) {
          ctx.fileImagem = file;
        });
    }

  }

  get request() {
    const retorno = cloneDeep(this);

    return retorno;
  }
}
