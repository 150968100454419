<template>
  <div>
    <!-- <pre>
      {{ form.vistasExplodidas?.map(el => el.iterador + ' - ' + (el.item?.codigo || el.codigo) ) }}
    </pre> -->
    <div class="upload-image">
      <input-file
        :value="formulario.visaoExplodida.dados.fileImagem"
        returns="base64"
        class="col-12 col-md-3"
        :placeholder="
          $t('modulos.incertezas.formulario.dados_incerteza.adicionar_imagem')
        "
        visualizador
        :regras-personalizadas="[regraArquivo]"
        :image="formulario.visaoExplodida.dados.imagem"
        :disabled="ehVisualizar"
        @handle-upload="handleUpload"
        @clear="desvincularImagem"
        @nome-imagem="nomeImagem"
      />
      <botao-padrao
        :disabled="!temImagem"
        class="button-save"
        @click="salvarImagem"
      >
        <v-icon>$mdiContentSaveOutline</v-icon>
        {{ $t('geral.botoes.enviar_imagem') }}
      </botao-padrao>
    </div>
    <div>
      <main>
        <div
          v-if="mostrarVisaoExplodida && !!temImagem"
          class="main-container"
        >
          <v-hotspot
            :init-options="hotspot"
            :overlay-text="$t('modulos.modelo_instrumento.imagem')"
            :tela-edicao="ehTelaEdicao"
            :hotspots-db="hotspotsFromDb"
            @save-data="saveData"
            @after-delete="afterDelete"
            @adiciona-dados-form="adicionaDadosForm"
          />
        </div>
      </main>
    </div>
  </div>
</template>
<script>
import { InputFile } from '@/components/inputs';
import { VisaoExplodidaModel } from '@/common/models/cadastros/VisaoExplodidaModel';
import VueHotspot from '@/components/VueHotspot/VueHotspot.vue';

import { generateGuid } from '@/components/VueHotspot/utils/common.js';

export default {
  components: { InputFile, 'v-hotspot': VueHotspot },
  props: {
    form: { type: Object, default: null },
  },

  data() {
    return {
      temDados: false,
      mostrarVisaoExplodida: false,
      ehTelaEdicao: false,
      revisao: 'visualizacao',
      isLoading: false,
      temImagem: false,
      hotspot: {
        image: null,
        editable: true,
        interactivity: 'hover',
      },
      formulario: {
        visaoExplodida: {
          dados: new VisaoExplodidaModel(),
        },
      },
      hotspotsFromDb: [],
    };
  },
  computed: {
    ehVisualizar(){
      return this.$route.name === 'modelo-instrumento-visualizar';
    },
  },

  mounted() {
    if (this.form.id) {
      this.setarValoresIniciais(this.form);
      this.temDados = true;
      this.ehTelaEdicao = true;
    } else {
      this.ehTelaEdicao = false;
      this.mostrarVisaoExplodida = true;
    }
  },
  methods: {
    setarValoresIniciais(formulario) {
      this.hotspot.image = formulario.imagem;

      if (formulario.vistasExplodidas.length) {
        this.hotspotsFromDb = formulario.vistasExplodidas.map((pontoImagem) => {
          return {
            x: pontoImagem.coordenadaX,
            y: pontoImagem.coordenadaY,
            iterador: pontoImagem.iterador.toString(),
            itemId: pontoImagem.item.id,
            codigo: pontoImagem.item.codigo,
            fabricante: pontoImagem.item.fabricante,
            descricao: pontoImagem.item.descricao,
            pontoId: pontoImagem.pontoId,
            id: generateGuid(), // para excluir elemento da tabela gerada no comp VueHotspot
          };
        });
      }

      if (!formulario.imagem && !formulario.vistasExplodidas.length) {
        this.mostrarVisaoExplodida = false;
        this.temImagem = false;
        return;
      }
      this.mostrarVisaoExplodida = true;
      this.temImagem = true;
    },
    changeEditable(conf) {
      if (!conf) return;
      conf.editable = !conf.editable;
    },
    saveData(data) {
      console.log(data);
    },
    afterDelete() {},
    regraArquivo(v) {
      if (!v) return true;
      if (v?.type == 'image/png') return true;
      return this.$t(`modulos.colaborador.validacoes.permitido_png`);
    },

    handleUpload(file) {
      this.form.imagem = file;

      this.hotspot.image = file;
      this.temImagem = !!file;
    },
    desvincularImagem() {
      this.form.imagem = null;
      this.form.nomeImagem = null;
    },
    nomeImagem(nomeImagem) {
      this.form.nomeImagem = nomeImagem ? nomeImagem : null;
    },
    adicionaDadosForm(value) {
      this.form.vistasExplodidas = [...value];
    },
    salvarImagem() {
      this.isLoading = true;
      this.mostrarVisaoExplodida = true;
    },
  },
};
</script>

<style>
.upload-image {
  display: flex;
  align-items: center;
}

.button-save {
  margin-top: 2.4rem;
}
.desc {
  margin-bottom: 3em;
  color: #7f8c8d;
}

.main-container {
  padding: 1rem;
}
</style>

<!-- <style lang="less">
@small: 1em;
@medium: 2em;
@gray: #666666;

.no-margin {
  margin: 0;
}
.mt-sm {
  margin-top: @small;
}
.mb-sm {
  margin-bottom: @small;
}
.ml-sm {
  margin-left: @small;
}
.mr-sm {
  margin-right: @small;
}
.mt-m {
  margin-top: @medium;
}
.mb-m {
  margin-bottom: @medium;
}
.ml-m {
  margin-left: @medium;
}
.mr-m {
  margin-right: @medium;
}

.no-padding {
  padding: 0;
}
.pt-sm {
  padding-top: @small;
}
.pb-sm {
  padding-bottom: @small;
}
.pl-sm {
  padding-left: @small;
}
.pr-sm {
  padding-right: @small;
}
.pt-m {
  padding-top: @medium;
}
.pb-m {
  padding-bottom: @medium;
}
.pl-m {
  padding-left: @medium;
}
.pr-m {
  padding-right: @medium;
}

html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  padding: 3em 0 0;
  font-family: 'Source Sans Pro', 'Helvetica Neue', Arial, sans-serif;
  color: @gray;
  text-align: center;
}
a {
  color: inherit;
  text-decoration: none;
}
h1 {
  margin-bottom: 1em;
  font-family: Dosis, 'Source Sans Pro', 'Helvetica Neue', Arial, sans-serif;
}
h1,
h2 {
  color: #2c3e50;
  font-weight: 300;
}
h2 {
  margin-top: 2em;
  padding-top: 1em;
  font-size: 1.2em;
}
button {
  margin-left: 1em;
  vertical-align: middle;
}
.desc {
  margin-bottom: 3em;
  color: #7f8c8d;
}
.main-container {
  display: inline-block;
  position: relative;
  margin: 2em auto;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  box-shadow: 0 0 45px rgba(0, 0, 0, 0.2);
  padding: 1.5em 2em;
  //min-width: calc(40vw + 4em);
  width: 50vw;
  .text {
    &.mb-sm {
      margin-bottom: 1em;
    }
    font-size: 1.2em;
    .bold {
      font-weight: bold;
    }
    .left {
      float: left;
    }
  }
}
footer {
  margin: 5em 0 3em;
  font-size: 0.5em;
  vertical-align: middle;
  a {
    display: inline-block;
    margin: 0 5px;
    padding: 3px 0 6px;
    color: #7f8c8d;
    font-size: 2em;
    text-decoration: none;
  }
  a:hover {
    padding-bottom: 3px;
    border-bottom: 3px solid #42b983;
  }
}

@media screen {
  @media (max-width: 980px) {
    section {
      border-top: 1px solid @gray;
      .container {
        width: 100vw;
        margin: @small auto;
        padding: 0;
        border: none;
        border-radius: 0;
        box-shadow: none;
      }
    }
  }
}
</style> -->
