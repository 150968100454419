<template lang="">
  <div class="mt-5">
    <div
      v-if="!ehVisualizar"
      class="d-flex align-center justify-space-between mb-3"
    >
      <div>
        <botao-padrao
          v-if="podeExcluir"
          class="my-2"
          outlined
          color="danger"
          @click="excluir"
        >
          {{ $t('geral.botoes.excluir') }}
        </botao-padrao>
      </div>
      <botao-padrao
        v-if="participanteLogadoConta"
        @click="abrirNovo"
      >
        <v-icon>$mdiPlusThick</v-icon>
        {{ $t('modulos.modelo_instrumento.botoes.associar_faixa') }}
      </botao-padrao>
    </div>

    <tabela-padrao-prime-vue
      v-model="tabela.selecionados"
      :dados="dadosTabela"
      mostrar-qtd-registros
      filtro-geral
      class="mt-2"
      :colunas="colunasTabela"
      :filters="tabela.filters"
      paginacao-em-memoria
      sem-paginacao
      :por-pagina="tabela.porPagina"
      :pagina="tabela.paginaAtual"
      :quantidade-itens="form.faixas?.length"
      ajustar-altura-linha
      ajustar-dropdown-acima
      :mostrar-acoes="participanteLogadoConta && !ehVisualizar"
      :mostrar-seletor="participanteLogadoConta && !ehVisualizar"
      :global-filters="colunasTabela.map(c => c.value)"
    >
      <template
        v-if="participanteLogadoConta"
        v-slot:acoes="{ slotProps }"
      >
        <dropdown-padrao
          text
          color="secondary"
        >
          <template #botao>
            <v-icon> $dotsVertical </v-icon>
          </template>
          <dropdown-padrao-item @click="abrirPontosCalibracao(slotProps.data)">
            {{ $t('modulos.tipo_instrumento.tabela.pontos_de_calibracao') }}
          </dropdown-padrao-item>
          <dropdown-padrao-item
            v-if="buscarPermissao('TipoInstrumento', 'Editar')"
            @click="redirecionarEditarFaixa(slotProps)"
          >
            {{ $t('modulos.modelo_instrumento.redirecionar_faixa') }}
          </dropdown-padrao-item>
        </dropdown-padrao>
      </template>
    </tabela-padrao-prime-vue>
    <FaixaModal
      ref="modal-faixa"
      @atualizarTabela="atualizarTabela"
    />
    <ModalPontosCalibracao
      ref="modal-pontos"
      :form="form"
    />
  </div>
</template>
<script>
import FaixaModal from './modais/FaixaModal';
import helpers from '@/common/utils/helpers';
import ModalPontosCalibracao from './modais/ModalPontosCalibracao.vue';
import { FilterMatchMode } from 'primevue/api';
import _ from 'lodash';

export default {
  components: { FaixaModal, ModalPontosCalibracao },
  props: {
    form: { type: Object, default: null },
    participanteLogadoConta: { type: Boolean, default: false },
  },
  data() {
    return {
      tabela: {
        dados: [],
        colunas: [
          {
            value: 'descricao',
            text: this.$t(
              'modulos.modelo_instrumento.tabela.faixasTable.faixa'
            ),
            sortable: true,
            formatter: (v) => helpers.formatarDescricao(v),
          },
          {
            value: 'resolucao',
            text: this.$t(
              'modulos.modelo_instrumento.tabela.faixasTable.resolucao'
            ),
            sortable: true,
            formatter: (v) => helpers.formatarNumeroComPrecisao(v),
          },
        ],
        selecionados: [],
        porPagina: 10,
        paginaAtual: 1,
        filters: {
          global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        },
      },
    };
  },
  computed: {
    podeExcluir() {
      return this.tabela.selecionados != 0;
    },
    colunasTabela() {
      const colunas = [
        {
          value: 'descricao',
          text: this.$t('modulos.modelo_instrumento.tabela.faixasTable.faixa'),
          sortable: true,
          formatter: (v) => helpers.formatarDescricao(v),
        },
        {
          value: 'amplitudeMinima',
          text: this.$t(
            'modulos.tipo_instrumento.tabela.faixas.amplitude_minima'
          ),
          sortable: true,
          formatter: (v) => helpers.formatarNumeroComPrecisao(v),
        },
        {
          value: 'amplitudeMaxima',
          text: this.$t(
            'modulos.tipo_instrumento.tabela.faixas.amplitude_maxima'
          ),
          sortable: true,
          formatter: (v) => helpers.formatarNumeroComPrecisao(v),
        },
        {
          value: 'unidadeMedida',
          text: this.$t(
            'modulos.tipo_instrumento.tabela.faixas.unidade_medida'
          ),
          sortable: true,
          formatter: (v) => `${v?.nome} (${v?.simbolo})`,
        },
        {
          value: 'resolucao',
          text: this.$t(
            'modulos.modelo_instrumento.tabela.faixasTable.resolucao'
          ),
          sortable: true,
          formatter: (v) => helpers.formatarNumeroComPrecisao(v),
        },
        {
          value: 'modeloInstrumentoFaixaPonto',
          text: this.$t(
            'modulos.tipo_instrumento.tabela.faixas.quantidade_pontos'
          ),
          sortable: true,
          formatter: (v) => v?.length ?? 0,
        },
      ];

      if (this.participanteLogadoConta) {
        return [...colunas];
      }
      colunas.shift();
      return [...colunas];
    },
    dadosTabela(){
      return _.sortBy(this.form.faixas, ['amplitudeMinima', 'amplitudeMaxima', 'resolucao' , 'descricao']);
    },
    ehVisualizar(){
      return this.$route.name === 'modelo-instrumento-visualizar';
    },
  },
  mounted() {
    this.dados = this.form.faixas;
  },
  methods: {
    buscarPermissao(funcionalidade, funcionalidadeAcao) {
      return helpers.buscarStatePermissoes(
        this,
        funcionalidade,
        funcionalidadeAcao
      );
    },
    redirecionarEditarFaixa() {
      this.confirmar(
        this.$t('modulos.modelo_instrumento.redirecionar_faixa'),
        this.$t('modulos.modelo_instrumento.confirmar_redirecionar_faixa')
      ).then(() => {
        if (!this.form.tipoInstrumento?.value)
          return this.$t(
            'modulos.modelo_instrumento.verifique_tipo_instrumento'
          );
        this.$router.push({
          name: 'tipo-instrumento-editar',
          params: {
            id: this.form.tipoInstrumento?.value,
            modeloInstrumentoId: this.form.id,
          },
          query: { editarFaixa: true },
        });
      });
    },
    abrirNovo() {
      if (!this.form.tipoInstrumento?.value) {
        this.toastAlerta(
          this.$t(`modulos.modelo_instrumento.erros.escolher_tipo_instrumento`)
        );
        return;
      }
      this.$refs['modal-faixa'].abrirModal(this.form);
    },
    abrirEditar(faixa) {
      this.$refs['modal-faixa'].abrirModal(faixa);
    },
    atualizarTabela() {
      this.tabela.dados = this.form.instrumentoFaixa;
    },
    abrirPontosCalibracao(faixa) {
      this.$refs['modal-pontos'].abrirModal(faixa);
    },
    excluir() {
      this.confirmarExcluir().then(() => {
        this.tabela.selecionados.forEach((element) => {
          this.form.faixas.splice(this.form.faixas.indexOf(element), 1);
        });
        this.tabela.selecionados = [];
      });
    },
  },
};
</script>
