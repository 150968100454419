<template>
  <div
    class="ui__vue_hotspot_hotspot"
    :style="`top: ${positionTop}; left: ${positionLeft}; background-color: ${hotspotColor};`"
    :class="isActive || interactivity === 'none' ? 'active' : ''"
    @mouseenter="interactivity === 'hover' ? (isActive = true) : null"
    @mouseleave="interactivity === 'hover' ? (isActive = false) : null"
    @click="clickHandler"
  >
    <!-- message box -->
    <span class="ui_points_value">{{ hotspot['iterador'] }}</span>
    <div class="ui_vue_hotspot_message_container" :style="`color:${textColor}`">
      <div
        class="ui__vue_hotspot_title"
        :style="`
          background: ${messageBoxColor};
          opacity: ${opacity}`"
      >
        <span>{{ hotspot['descricao'] }}</span>
      </div>
      <div
        class="ui__vue_hotspot_message"
        :style="`
          background: ${messageBoxColor};
          opacity: ${opacity}`"
      >
        <span>Fabricante: {{ hotspot['fabricante'] }}</span>
        <span>Código: {{ hotspot['codigo'] }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { throttle } from '../utils/common.js';
import {
  createComponent,
  ref,
  reactive,
  toRefs,
  onMounted,
  onUnmounted,
  computed,
  watch,
} from '@vue/composition-api';

export default createComponent({
  props: {
    hotspot: Object,
    config: Object,
    imageLoaded: Boolean,
    vueHotspotBackgroundImage: HTMLImageElement,
    vueHotspot: HTMLDivElement,
  },
  setup(props, { emit }) {
    const isActive = ref(false);
    const conf = reactive({
      positionTop: 0,
      positionLeft: 0,
      hotspotColor: computed(() => props.config && props.config.hotspotColor),
      interactivity: computed(() => props.config && props.config.interactivity),
      textColor: computed(() => props.config && props.config.textColor),
      messageBoxColor: computed(
        () => props.config && props.config.messageBoxColor
      ),
      opacity: computed(() => props.config && props.config.opacity),
    });

    watch(
      () => props.imageLoaded,
      (loaded) => {
        if (loaded) {
          getHotspotStyle();
        }
      }
    );

    onMounted(() => {
      window.addEventListener('resize', throttle(getHotspotStyle, 50));
    });

    onUnmounted(() => {
      window.removeEventListener('resize', throttle(getHotspotStyle, 50));
    });

    function getHotspotStyle() {
      conf.positionTop = `${
        (props.hotspot.y * props.vueHotspotBackgroundImage.clientHeight) / 100 +
        (props.vueHotspotBackgroundImage.offsetTop - props.vueHotspot.clientTop)
      }px;`;
      conf.positionLeft = `${
        (props.hotspot.x * props.vueHotspotBackgroundImage.clientWidth) / 100 +
        (props.vueHotspotBackgroundImage.offsetLeft -
          props.vueHotspot.clientLeft)
      }px;`;
    }

    function toggleActive() {
      takeHotspotValues();
      isActive.value = !isActive.value;
    }
    function clickHandler() {
      if (props.interactivity === 'click') {
        toggleActive();
      }
      takeHotspotValues();
    }

    function takeHotspotValues() {
      emit('getHotspotClickedData', props.hotspot);
    }

    return {
      // data
      isActive,
      ...toRefs(conf),
      // methods
      getHotspotStyle,
      toggleActive,
      takeHotspotValues,
      clickHandler,
    };
  },
});
</script>

<style scoped>
/* CSS class for hotspot data points */
.ui__vue_hotspot_hotspot {
  height: 25px;
  width: 25px;
  position: absolute;
  border-radius: 50%;
  cursor: pointer;
  z-index: 200;
  margin-left: -10px;
  margin-top: -10px;
  padding: 4px;
}
.ui__vue_hotspot_hotspot > div {
  min-width: 250px;
  width: 250px;
  height: 94px;
  margin: -104px -60px;
  border-radius: 4px;
  overflow: hidden;
  font-size: 10px;
  display: none;
}
.ui__vue_hotspot_hotspot.active > div {
  display: block; /* Required */
}
.ui__vue_hotspot_hotspot.active > div:before {
  border: solid transparent;
  content: ' ';
  height: 0;
  left: 0;
  position: absolute;
  width: 0;
  border-width: 10px;
  border-left-color: rgba(255, 255, 255, 0.4);
  transform: rotate(90deg);
  top: -10px;
}
.ui__vue_hotspot_hotspot > div > .ui__vue_hotspot_title {
  height: 35px;
  line-height: 20px;
  font-weight: bold;
  transition: opacity 0.2s ease-in;
  font-size: 0.9rem;

  display: flex;

  justify-content: center;
  flex-direction: column;
}
.ui__vue_hotspot_hotspot > div > .ui__vue_hotspot_message {
  margin-top: 2px;
  padding: 10px 10px;
  height: 70px;
  overflow-y: auto;
  transition: opacity 0.2s ease-in;
  display: flex;
  flex-direction: column;
}
.ui__vue_hotspot_message span {
  font-size: 0.8rem;
  opacity: 0.8;
}
.ui__vue_hotspot_title span {
  margin-left: 11px;
}
.ui_points_value {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 0.7rem;
  font-weight: bold;
  color: rgb(254, 254, 254);
  z-index: 100;
  pointer-events: none;
}
</style>
