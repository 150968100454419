<template>
  <div>
    <div
      v-if="config"
      ref="vueHotspot"
      class="ui__vue_hotspot"
    >
      <!-- image -->
      <img
        ref="vueHotspotBackgroundImage"
        class="ui__vue_hotspot_background_image"
        :src="config.image"
        alt="Hotspot Image"
        @load="successLoadImg"
      >
      <!-- overlay -->
      <span
        v-if="config.editable"
        ref="vueHotspotOverlay"
        class="ui__vue_hotspot_overlay"
        :style="`height: ${overlayHeight}; width: ${overlayWidth}; left: ${overlayLeft}; top: ${overlayTop};`"
        @click.stop.prevent="addHotspot"
      >
        <p>{{ overlayText }}</p>
      </span>
      <!-- hotspot DataPoint -->
      <template>
        <div :key="keyAtualizaDataPoint">
          <DataPoint
            v-for="(hotspot, idx) in config.data"
            :key="idx"
            :hotspot="hotspot"
            :config="config"
            :image-loaded="imageLoaded"
            :vue-hotspot-background-image="vueHotspotBackgroundImage"
            :vue-hotspot="vueHotspot"
            @getHotspotClickedData="getHotspotClickedData"
          />
        </div>
      </template>
      <!-- ControlBox -->
      <ControlBox
        :config="config"
        @save-data="saveAllHotspots"
        @after-delete="removeAllHotspots"
      />
    </div>
    <h3 v-if="config.data.length && initOptions.editable">
      Peças Selecionadas
    </h3>
    <div class="d-flex align-center">
      <botao-padrao
        v-if="
          tabela.selecionados.length > 0 &&
            config.data.length > 0 &&
            initOptions.editable
        "
        outlined
        color="danger"
        @click="excluirRegistros"
      >
        <v-icon>$mdiTrashCanOutline</v-icon>
        {{ $t('geral.botoes.excluir') }}
      </botao-padrao>
    </div>
    <tabela-padrao
      v-if="config.data.length > 0 && initOptions.editable"
      v-model="tabela.selecionados"
      :dados="config.data"
      class="mt-2 paginator"
      :colunas="tabela.colunas"
      :por-pagina="tabela.porPagina"
      :quantidade-paginas="tabela.quantidadePaginas"
    />
    <pecas-modal
      ref="pecasModalRef"
      :instrumentos-ids="defaultOptions.instrumentosSelecionadosIds"
      :hotspot-data="hotspotValues.hotspotData"
      :pontos-ja-selecionados="config.data"
      @close-modal="manipulaModalPesquisaPecas('', 'close')"
      @close-modal-add="manipulaModalPesquisaPecas($event, 'add')"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import DataPoint from './module/DataPoint.vue';
import ControlBox from './module/ControlBox.vue';
import {
  throttle,
  generateGuid,
} from './utils/common.js';

import VueCompositionApi, {
  createComponent,
  ref,
  reactive,
  toRefs,
  isRef,
  onMounted,
  onUnmounted,
  watch,
} from '@vue/composition-api';
import PecasModal from '@/views/modulos/cadastros/modelo_instrumento/components/modais/PecasModal.vue';
import _ from 'lodash'
Vue.use(VueCompositionApi);

export default createComponent({
  components: {
    DataPoint,
    ControlBox,
    PecasModal,
  },
  props: {
    overlayText: String,
    initOptions: Object,
    telaEdicao: Boolean,
    hotspotsDb: Array,
  },
  setup(props, { emit }) {
    const vueHotspot = ref(null);
    const vueHotspotOverlay = ref(null);
    const vueHotspotBackgroundImage = ref(null);
    const pecasModalRef = ref(null);
    const keyAtualizaDataPoint = ref(0);
    const iterator = reactive({ index: 0 });

    const hotspotValues = reactive({ hotspotData: null });
    const tabela = ref({
      selecionados: [],
      dados: [],
      colunas: [
        {
          value: 'iterador',
          text: 'Identificador',
          sortable: true,
          width: 150,
        },
        // {
        //   value: 'x',
        //   text: 'Coordenada X',
        //   sortable: true,
        //   width: 200,
        // },
        // {
        //   value: 'y',
        //   text: 'Coordenada Y',
        //   sortable: true,
        //   width: 200,
        // },
        {
          value: 'codigo',
          text: 'Código',
          sortable: true,
          width: 300,
        },
        {
          value: 'fabricante',
          text: 'Fabricante',
          sortable: true,
          width: 150,
        },
        {
          value: 'descricao',
          text: 'Descricao',
          sortable: true,
        },
      ],
      quantidadeItens: 0,
      quantidadePaginas: 0,
      paginaAtual: 1,
      porPagina: 10,
    });

    const defaultOptions = reactive({
      instrumentosSelecionadosIds: [],
      numeroItemPecasSelecionadas: null,
      // Object to hold the hotspot data points
      data: [],
      showOnScren: [],
      // Default image placeholder
      image:
        'https://via.placeholder.com/600x500?text=Oops!+image+not+found...',

      // Specify editable in which the plugin is to be used
      // `true`: Allows to create hotspots from UI
      // `false`: Display hotspots from `data` object
      editable: true,

      // Event on which the hotspot data point will show up
      // allowed values: `click`, `hover`, `none`
      interactivity: 'hover',

      // background color for hotspots
      hotspotColor: 'rgb(66, 184, 131)',
      messageBoxColor: 'rgb(255, 255, 255)',
      textColor: 'rgb(53, 73, 94)',

      // overlay text
      // overlayText: props.$t('modulos.cadastros.modelo_instrumento.visao_explodida.overlayText'),
      overlayText: '',

      // opacity for hotspots, default is 0.8
      opacity: 0.8,

      // Hotspot schema
      schema: [
        {
          property: 'descricao',
          default: '',
        },
        {
          property: 'codigo',
          default: '',
        },
        {
          property: 'itemId',
          default: '',
        },
        {
          property: 'fabricante',
          default: '',
        },
      ],
    });

    const config = ref(null);
    const imageLoaded = ref(false);
    const frameSize = reactive({
      overlayHeight: 0,
      overlayWidth: 0,
      overlayLeft: 0,
      overlayTop: 0,
    });

    watch(imageLoaded, (loaded) => {
      if (loaded) {
        resizeOverlay();
      }
    });

    watch(
      () => props.initOptions,
      (initOptions) => {
        // overwrite defaults with initOptions
        config.value = {
          ...(config.value ? config.value : deepCopy(defaultOptions)),
          ...initOptions,
        };
      },
      {
        deep: true,
      }
    );

    onMounted(() => {
      window.addEventListener('resize', throttle(resizeOverlay, 50));
      if (props.telaEdicao) {
        config.value.data = [...props.hotspotsDb];
      }
    });

    onUnmounted(() => {
      window.removeEventListener('resize', throttle(resizeOverlay, 50));
    });

    function addHotspot(e) {
      const relativeX = e.offsetX;
      const relativeY = e.offsetY;
      const height = vueHotspotOverlay.value.offsetHeight;
      const width = vueHotspotOverlay.value.offsetWidth;
      const hotspot = {
        x: (relativeX / width) * 100,
        y: (relativeY / height) * 100,
      };

      hotspotValues.hotspotData = hotspot;
      openModalWithDelay();
    }

    function resizeOverlay() {
      const image = isRef(vueHotspotBackgroundImage)
        ? vueHotspotBackgroundImage.value
        : vueHotspotBackgroundImage;
      const frame = isRef(vueHotspot) ? vueHotspot.value : vueHotspot;
      frameSize.overlayHeight = `${
        (image.clientHeight / frame.clientHeight) * 100
      }%`;
      frameSize.overlayWidth = `${
        (image.clientWidth / frame.clientWidth) * 100
      }%`;
      frameSize.overlayLeft = `${image.offsetLeft - frame.clientLeft}px`;
      frameSize.overlayTop = `${image.offsetTop - frame.clientTop}px`;
    }

    function takeHotspotValues(hotspot) {
      if (findHotspotById(hotspot)) return;
      const unWrappedConfig = isRef(config) ? config.value : config;
      unWrappedConfig.data.push(hotspot);
    }

    function findHotspotById(hotspot) {
      const unWrappedConfig = isRef(config) ? config.value : config;

      return unWrappedConfig.data.find((el) => el.id === hotspot.id);
    }

    function deepCopy(obj) {
      return JSON.parse(JSON.stringify(obj));
    }

    function successLoadImg(event) {
      if (event.target.complete === true) imageLoaded.value = true;
    }

    function saveAllHotspots() {
      const unWrappedConfig = isRef(config) ? config.value : config;
      emit('save-data', unWrappedConfig.data);
    }

    function removeAllHotspots() {
      emit('after-delete');
    }

    function getHotspotClickedData(data){
      emit('getHotspotClickedData', data)
    }

    function excluirRegistros() {
      const unWrappedConfig = isRef(config) ? config.value : config;
      unWrappedConfig.data = unWrappedConfig.data.filter(
        (p) =>
          !tabela.value.selecionados.some(
            (selected) => selected.codigo === p.codigo
          )
      );
      unWrappedConfig.data = _.orderBy(unWrappedConfig.data, el => parseFloat(el.iterador))
      unWrappedConfig.data.forEach((el, index) => el.iterador = index + 1);

      config.value.data = unWrappedConfig.data;
      tabela.value.selecionados = [];
      ++keyAtualizaDataPoint.value;
      emit('adiciona-dados-form', unWrappedConfig.data);
    }

    const openModalWithDelay = () => {
      setTimeout(() => {
        pecasModalRef.value.abrirModal([]);
      }, 100);
    };
    function manipulaModalPesquisaPecas(data) {
      const unWrappedConfig = isRef(config) ? config.value : config;
      const hotspot = data.hotspotData;
      const schema = unWrappedConfig.schema;

      const propertyMapping = {
        descricao: () => data.selected.descricao,
        codigo: () => data.selected.codigo,
        itemId: () => data.selected.id,
        fabricante: () => data.selected.participanteFabricante,
      };

      for (const value of schema) {
        const assignValue =
          propertyMapping[value.property] || propertyMapping.default;
        hotspot[value.property] = assignValue();
      }
      hotspot.id = generateGuid();
      if (config.value.data.length > 0) {
        const lastHotspot = _.maxBy(config.value.data, el => parseFloat(el.iterador));
        hotspot.iterador = (parseFloat(lastHotspot?.iterador) + 1) || 1;
      } else hotspot.iterador = iterator.index + 1;

      unWrappedConfig.data.push(hotspot);
      takeHotspotValues(config.value.data[config.value.data.length - 1]);
      const objetosSemId = unWrappedConfig.data.map((objeto) => {
        const objetoSemId = { ...objeto };
        delete objetoSemId.id;
        return objetoSemId;
      });
      emit('adiciona-dados-form', objetosSemId);
    }
    return {
      // data
      pecasModalRef,
      defaultOptions,
      hotspotValues,
      config,
      imageLoaded,
      ...toRefs(frameSize),
      tabela,
      keyAtualizaDataPoint,

      // dom
      vueHotspot,
      vueHotspotOverlay,
      vueHotspotBackgroundImage,

      // methods
      deepCopy,
      successLoadImg,
      saveAllHotspots,
      removeAllHotspots,
      resizeOverlay,
      addHotspot,
      takeHotspotValues,
      generateGuid,
      excluirRegistros,
      manipulaModalPesquisaPecas,
      getHotspotClickedData
    };
  },
});
</script>

<style>
.values {
  padding: 1rem;
  display: flex;
  margin-bottom: 1rem;
  flex-direction: column;
  margin-top: 20px;
  background-color: aliceblue;
}
.ui__vue_hotspot {
  width: 100%;
  height: 100%;
  display: inline-block;
  position: relative;
}
.ui__vue_hotspot_background_image {
  max-width: 100%;
  width: 100%;
}
/* CSS class for overlay used in `editable:true` mode */
span.ui__vue_hotspot_overlay {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.4);
  top: 0px;
  left: 0px;
  cursor: pointer;
}
span.ui__vue_hotspot_overlay > p {
  color: #ffffff;
  background: rgba(255, 255, 255, 0.4);
  margin-top: 0px;
  padding: 20px;
  text-align: center;
}
.paginator > .paginacao-padrao {
  display: none !important;
}
</style>
