<template>
  <div>
    <template>
      <div class="mb-8">
        <div class="titulo-pagina">
          {{ tituloFormulario }}
        </div>
        <div v-if="form.id">
          <v-chip class="mr-1" small> Modelo: {{ form.nome }} </v-chip>
        </div>
      </div>
      <v-tabs
        v-model="indiceAba"
        class="abas-padrao"
        background-color="primary"
        color="primary"
      >
        <v-tab>
          {{
            $t(
              'modulos.modelo_instrumento.formulario.abas.dados_do_modelo_de_instrumento'
            )
          }}
        </v-tab>
        <v-tab>
          {{ $t('modulos.modelo_instrumento.formulario.abas.faixas') }}
        </v-tab>
        <v-tab>
          {{
            $t('modulos.modelo_instrumento.formulario.abas.campos_adicionais')
          }}
        </v-tab>
        <v-tab @click="setaValorAbaVistaExplodida">
          {{ $t('modulos.modelo_instrumento.formulario.abas.vista_explodida') }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="indiceAba">
        <v-tab-item>
          <dados-modelo
            ref="dados-modelo"
            :form="form"
            :participante-logado-conta="participanteLogadoConta"
          />
        </v-tab-item>
        <v-tab-item>
          <Faixas
            :form="form"
            :participante-logado-conta="participanteLogadoConta"
          />
        </v-tab-item>
        <v-tab-item>
          <campos-adicionais
            :form="form"
            :participante-logado-conta="participanteLogadoConta"
            @validarCamposAdicionais="validarCamposAdicionais"
          />
          <div />
        </v-tab-item>
        <v-tab-item>
          <visao-explodida v-if="vistaExplodidaClicada" :form="form" />
        </v-tab-item>
      </v-tabs-items>
      <template>
        <v-divider class="my-5" />
        <div class="d-flex align-center justify-end">
          <botao-padrao
            outlined
            color="secondary"
            class="mr-2"
            @click="cancelar"
          >
            <v-icon v-if="ehVisualizar">$mdiArrowLeft</v-icon>
            <v-icon v-else>
              $mdiCancel
            </v-icon>
            {{ botaoCancelarOuVoltar }}
          </botao-padrao>
          <botao-padrao
            v-if="participanteLogadoConta && !ehVisualizar"
            @click="confirmacaoSalvar"
          >
            <v-icon>$mdiContentSaveOutline</v-icon>
            {{ $t('geral.botoes.salvar') }}
          </botao-padrao>
        </div>
      </template>
    </template>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import ModeloInstrumentoService from '@common/services/cadastros/ModeloInstrumentoService.js';
import { ModeloInstrumentoModel } from '@common/models/cadastros/ModeloInstrumentoModel';
import DadosModelo from './components/DadosModelo.vue';
import Faixas from './components/Faixas.vue';
import VisaoExplodida from './components/VisaoExplodida.vue';
import CamposAdicionais from './components/CamposAdicionais.vue';
import helpers from '@common/utils/helpers';

export default {
  components: { DadosModelo, Faixas, CamposAdicionais, VisaoExplodida },
  props: {
    id: { type: [String, Number], default: null },
  },
  emits: ['validarCamposAdicionais'],
  data() {
    return {
      camposAdicionaisValido: false,
      indiceAba: 3,
      valido: false,
      form: new ModeloInstrumentoModel({}),
      vistaExplodidaClicada:false,
    };
  },
  computed: {
    ...mapGetters('Autenticacao', ['participanteLogadoConta']),
    tituloFormulario: function () {
      if (!this.participanteLogadoConta || this.ehVisualizar)
        return this.$t('modulos.modelo_instrumento.titulos.visualizar');
      if (this.id) return this.$t('modulos.modelo_instrumento.titulos.editar');
      return this.$t('modulos.modelo_instrumento.titulos.novo');
    },
    tooltipBotaoSalvar: function () {
      if (this.valido && this.camposAdicionaisValido) return '';

      return this.$t(
        'modulos.modelo_instrumento.validacoes.formulario_invalido'
      );
    },
    botaoCancelarOuVoltar: function () {
      if (!this.participanteLogadoConta || this.ehVisualizar) {
        return this.$t('geral.botoes.voltar');
      }
      return this.$t('geral.botoes.cancelar');
    },
    ehVisualizar(){
      return this.$route.name === 'modelo-instrumento-visualizar';
    },
  },
  watch: {
    id: function () {
      if (this.id) this.buscar();
    },
  },
  mounted() {
    this.indiceAba = 0;
    if (this.id) {
      this.buscar(this.id)
      helpers.redirecionarSemPermissao(this, 'ModeloInstrumento', 'Editar')
    }
    helpers.redirecionarSemPermissao(this, 'ModeloInstrumento', 'Inserir');
    this.verificarEdicaoFaixa();
  },
  methods: {
    setaValorAbaVistaExplodida(){
      this.vistaExplodidaClicada = true;
    },
    verificarEdicaoFaixa: async function () {
      await this.$nextTick();
      if (this.$route.query?.editarFaixa) this.indiceAba = 1;
    },
    validarCamposAdicionais: function (camposAdicionaisValidacao) {
      this.camposAdicionaisValido = camposAdicionaisValidacao;
    },
    mostrarIdInvalido: function () {
      this.toastErro(this.$t('modulos.modelo_instrumento.erros.id_invalido'));
    },
    buscar: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      ModeloInstrumentoService.buscar(this.id)
        .then((res) => {
          this.form = new ModeloInstrumentoModel(res.data);
        })
        .catch(() => {
          this.mostrarIdInvalido();
        })
        .finally(() => {
          this.valido = this.$refs['dados-modelo'].$refs.form.validate();
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    confirmacaoSalvar: function () {
      if (this.id) {
        this.confirmarSalvar().then(() => {
          this.salvar();
        });
      } else this.salvar();
    },
    salvar: function () {
      if (this.$refs['dados-modelo']) {
        this.valido = this.$refs['dados-modelo'].$refs.form.validate();
      }
      if (!this.valido) return;
      this.$store.dispatch('Layout/iniciarCarregamento');
      ModeloInstrumentoService.salvar(this.form.request)
        .then(() => {
          if (!this.form.id) {
            this.toastSucesso(
              this.$t(`modulos.modelo_instrumento.cadastro_sucesso`)
            );
          } else {
            this.toastSucesso(
              this.$t(`modulos.modelo_instrumento.edicao_sucesso`)
            );
          }

          this.$router.push({ name: 'modelo-instrumento' });
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    cancelar: function () {
      if (!this.participanteLogadoConta || this.ehVisualizar)
        return this.$router.push({ name: 'modelo-instrumento' });
      this.confirmarCancelar().then(() => {
        this.$router.push({ name: 'modelo-instrumento' });
      });
    },
  },
};
</script>
